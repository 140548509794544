<template>
    <div>
        <b-card>
            <b-row>
                <b-col>
                <b-form-group label="Category Name">
                    <span class="output_span">{{bindObj.categoryName}}</span>
                </b-form-group>
                </b-col>
                <b-col>
                <b-form-group label="Manage By Student">
                    <span class="output_span">{{bindObj.manageByStudent}}</span>
                </b-form-group>
                </b-col>
                <b-col>
                <b-form-group label="No. of Documents">
                    <span class="output_span">{{bindObj.docNo}}</span>
                </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <b-card>
             <b-row>     
                <!-- SEARCH INPUT -->
                <b-col
                md="2"
                sm="4"
                >
                <b-form-group    
                    class="mb-0"
                >     
                    <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                    />
                </b-form-group>
                </b-col>
                <b-col md="4"/><b-col md="2"/><b-col md="2"/>               
                <!-- TABLE -->
                <b-col cols="12">
                <b-table
                    striped
                    hover
                    responsive
                    :items="moduleList"
                    :fields="columns"       
                    :filter="filter"
                    show-empty
                    empty-text="No matching records found"
                    busy.sync="isSpinner"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    class="position-relative edu_categorytable mt-2"
                >
                    <template slot="empty">
                        <div v-if="isSpinner" class="text-center">
                            <div v-if="isSpinner" class="loading loading-custom">         
                                    <div class="effect-1 effects"></div>
                                    <div class="effect-2 effects"></div>
                                    <div class="effect-3 effects"></div>     
                            </div> 
                        </div> 
                        <div v-else class="text-center">
                        No rows to display!
                        </div> 
                    </template>
                    <template #cell(no)="data">
                        {{Number(data.index) + 1}}
                    </template>
                     <template #cell(demoFile)="data">
                       <img v-show="data.item.demoFile.length > 0" src='@/assets/images/erflog/Download.png' @click="downloadAttachments(data)" class="action_icon"/>
                    </template>                                 
                </b-table>
                <!-- <p v-show="moduleList.length == 0 && isSpinner == false" style="color: red;font-size: small;font-style: italic;text-align: center;">No Data Found</p> -->
                </b-col>               
            </b-row>
        </b-card>
    </div>
</template>
<script>
import 
{ 
    BCard,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BTable
} 
 from 'bootstrap-vue'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import { dbCollections, settingsCollectionDocs } from '@/utils/firebaseCollection.js';
export default {
   components: {
       BCard,
       BFormGroup,
       BRow,
       BCol,
       BFormInput,
       BTable
   },
   data(){
       return{
           editId:'',
           allDocuments:[],
           allCategory:[],
           bindObj:{},
           perPage: 10,
           pageOptions: [10, 25, 50, 100],
           totalRows: 1,
           currentPage: 1,
           filter: null,
           filterOn: [],
           moduleList:[],
           columns:[
            //    {
            //     label: 'No.',
            //     key: 'no',  
            //     width: '150px'
            //    },
               {
                label: 'Document Name',
                key: 'docName',  
                width: '150px'
               },
               {
                label: 'File Type',
                key: 'fileType',  
                width: '150px'
               },
               {
                label: 'Demo File',
                key: 'demoFile',  
                width: '150px'
               },
            ],
            isSpinner:false
       }
   },
   watch: {
    $route: function () {        
        this.getData();          
    }
   },
   created(){
       this.getData();       
       this.$root.$on('openCategoryEditPage',()=>{
            setTimeout(()=>{
                this.$router.push({name:'Edit-doc-category', query: {'editcategoryId':this.editId} })
            },1000)            
       })
   },
   methods:{
      getData(){
        setTimeout(()=>{
            this.isSpinner = true;    
            getQuery.getDataWithSpecificWhereCondition(dbCollections.DOCUMENTS,'isDeleted','==',false,res=>{  
            this.allDocuments = res;
            this.isSpinner = false;    
            })
            this.isSpinner = true; 
            getQuery.getDataWithSpecificWhereCondition(settingsCollectionDocs.DOCUMENTCATEGORY,'isDeleted','==',false,res=>{
                this.moduleList = [];
                this.allCategory = res; 
                this.isSpinner = false;   
                this.BindData();
            })
        },1000)  
               
      },
      BindData(){
        var self = this;    
        this.isSpinner = true;    
        if(Object.keys(self.$route.query).length !== 0){       
                  let updateId = self.$route.query.editId;
                  this.editId = updateId;
                  if(updateId !== undefined){
                     let searchInd = this.allCategory.findIndex(val=>{
                         return val.id == updateId;
                     })  
                     if(searchInd > -1){
                       self.bindObj = self.allCategory[searchInd];     
                       self.bindObj.docNo = self.allCategory[searchInd].documents.length;
                        if(self.bindObj.documents !== null){
                        let assignDoc = [];    
                        self.bindObj.documents.forEach(element => {
                            let searchDoc = self.allDocuments.findIndex(val=>{
                                return val.id == element; 
                            })
                            if(searchDoc > -1){
                                assignDoc.push(self.allDocuments[searchDoc])
                                this.moduleList.push({
                                    'no':self.allDocuments[searchDoc].id,
                                    'docName': self.allDocuments[searchDoc].documentName,
                                    'fileType': self.allDocuments[searchDoc].fileType,
                                    'demoFile': self.allDocuments[searchDoc].docFile
                                })
                            }                           
                        });
                        this.isSpinner = false;    
                        console.log(assignDoc,"assignDoc")
                        }
                     }
                  }
        }else{
            this.isSpinner = false;    
        }        
      },
      onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      },
      downloadDocument(data,cb){
      let url = data.imageUrl;
      let name = data.imageName;       
      this.$axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download',name);
            document.body.appendChild(fileLink);
            fileLink.click();
            cb(true) 
        })
        .catch(error=>{
             this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: error,
                        icon: 'CoffeeIcon',
                        variant: 'danger',                               
                    },
            })         
        })
      }, 
      downloadAttachments(data){
          console.log(data.item.demoFile,"JYOTI")
          if(data.item.demoFile.length > 0){
            let count = 0;
            const uploadFileFunction = (row) => {
               if(count >= data.item.demoFile.length){        
                   return           
               }
                this.downloadDocument(row,(saveRes)=>{
                    count += 1;
                    // if(data.item.upload_demo_file.length < count){
                        uploadFileFunction(data.item.demoFile[count]);
                    // }
                });              
            }
            uploadFileFunction(data.item.demoFile[count]);
          }
      }
   }
}
</script>
<style scoped>
.position-relative.edu_categorytable.table-responsive {
    padding: 0px!important;
}
</style>